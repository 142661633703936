import { useMediaQuery } from 'react-responsive';
import { Container } from '@mui/material';

interface FeaturesCardProps {
    header: string;
    bodyText1: string;
    bodyText2: string;
    image: string;
}

const FeaturesCard = ({ header, bodyText1, bodyText2, image }: FeaturesCardProps): JSX.Element => {
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const getDesktop = (): JSX.Element => (
        <div
            style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                height: '532px',
                flexShrink: 0,
                borderRadius: '24px',
                background: '#FFF',
                padding: '40px',
            }}
        >
            <p style={{
                color: '#222',
                textAlign: 'left',
                fontFamily: 'Instrument Sans',
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                letterSpacing: '-0.96px',
                marginBottom: '32px',
            }}>{header}</p>
            <div style={{ width: '50%' }}>
                <p style={{
                    color: '#322A21',
                    fontFamily: 'Instrument Sans',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    letterSpacing: '-0.48px',
                    textAlign: 'left',
                    marginBottom: '32px',
                }}>{bodyText1}</p>
                <p style={{
                    color: '#322A21',
                    fontFamily: 'Instrument Sans',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    letterSpacing: '-0.48px',
                    textAlign: 'left',
                }}>{bodyText2}</p>
            </div>
            <img
                src={image}
                alt='iphone'
                style={{
                    width: '270px',
                    position: 'absolute',
                    right: 20,
                    bottom: 0,
                }}
            />
        </div>
    );
    const getMobile = (): JSX.Element => (
        <Container
            style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'start',
                width: '350px',
                flexShrink: 0,
                borderRadius: '24px',
                background: '#FFF',
                paddingTop: '40px',
                paddingRight: '21px',
                paddingLeft: '21px',
                marginRight: '24px',
            }}
        >
            <p style={{
                color: '#222',
                textAlign: 'center',
                fontFamily: 'Instrument Sans',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                letterSpacing: '-0.96px',
                marginBottom: '16px',
            }}>{header}</p>
            <div>
                <p style={{
                    color: '#322A21',
                    fontFamily: 'Instrument Sans',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    letterSpacing: '-0.48px',
                    textAlign: 'left',
                    marginBottom: '16px',
                }}>{bodyText1}</p>
                <p style={{
                    color: '#322A21',
                    fontFamily: 'Instrument Sans',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    letterSpacing: '-0.48px',
                    textAlign: 'left',
                    marginBottom: '14px',
                }}>{bodyText2}</p>
            </div>
            <img
                src={image}
                alt='iphone'
                style={{
                    width: '276px',
                }}
            />
        </Container>
    );
    return isMobile ? getMobile() : getDesktop();
}

export default FeaturesCard;
