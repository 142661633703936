interface TagProps {
    value: string,
}

const Tag = ({ value }:TagProps): JSX.Element => (
    <div style={{
        width: 'fit-content',
        display: 'inline-flex',
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '25px',
        border: '0.5px solid #BFBFBF',
        background: '#FFF'
    }}>
        <div style={{ width: '12px', height: '12px', borderRadius: '12px', backgroundColor: '#FF7337' }}></div>
        <span>{value}</span>
    </div>
);

export default Tag;
