import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'

interface SocialButtonProp {
  icon: string,
  header: string,
  subHeader: string,
  onClick: MouseEventHandler<HTMLButtonElement>,
}

const SocialButtonAlt = ({ icon, header, subHeader, onClick, ...props }: SocialButtonProp): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <Button onClick={onClick} variant="light" style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '8px' }}>
    <div style={{ marginRight: '12px' }}>
    <img src={icon} alt='apple' height={isMobile ? '34px' : '48px'} />
    </div>
    <div style={{
      display: "flex",
      flexDirection: 'column',
      alignItems: 'start'
    }}>
      <p style={{ color: '#000', fontWeight: '500', fontSize: isMobile ? '10px' : '12px' }}>{header}</p>
      <p style={{ color: '#000', fontWeight: '500', fontSize: isMobile ? '15px' : '24px' }}>{subHeader}</p>
    </div>
  </Button>
);
}

export default SocialButtonAlt;
