import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LandingPage from './pages/landingPage';
import StripePage from './pages/payment/stripePage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicyPage from './pages/privacyPolicy/privacyPolicyPage';
import DeleteAccountPage from './pages/deleteAccountPage/deleteAccountPage';
import SupportPage from './pages/supportPage/supportPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route path="/payment" element={<StripePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/delete-account" element={<DeleteAccountPage />} />
        <Route path="/support" element={<SupportPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
