import { useMediaQuery } from 'react-responsive';

const FooterSection = (): JSX.Element => { 
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    return (
    <section>
        <p style={{
            color: '#FF7337',
            fontFamily: 'Instrument Sans',
            fontSize: isMobile ? '56px' : '20rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '56px'
        }}>
            Offside.
        </p>
        <footer style={{ padding: '25px 0px' }}>
            <span style={{ fontWeight: '600' }}>&copy; </span>
            <span>2023, Offside</span>
        </footer>
    </section>
);
}

export default FooterSection;
