import iPhoneEventsPageImage from '../../assets/images/iphone-events-page.png';
import Tag from '../tag/tag';
import { useMediaQuery } from 'react-responsive'

const IntroductionCard = (): JSX.Element => {
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const getDesktop = () => (
        <>
            <div style={{
                width: isMobile ? '345px' : '100%',
                borderRadius: '54px',
                background: '#FF7337',
                height: '613px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '32px',
                padding: '90px',
            }}>
                <Tag value="Introduction" />
                <p style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Instrument Sans',
                    fontSize: '48px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.96px',
                }}>
                    Welcome to offside
                </p>
                <p
                    style={{
                        color: '#F9F9F9',
                        fontFamily: 'Instrument Sans',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: ' -0.48px',
                        width: '646px',
                        textAlign: 'left',
                    }}>
                    At Offside, we understand that soccer is more than just a sport; it's a passion that brings people together.
                </p>
                <p
                    style={{
                        color: '#F9F9F9',
                        fontFamily: 'Instrument Sans',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: ' -0.48px',
                        width: '646px',
                        textAlign: 'left',
                    }}>
                    Our app is designed to seamlessly integrate every aspect of your soccer journey, from booking the perfect pitch to rallying your team for an epic match.
                </p>
                <img
                    src={iPhoneEventsPageImage}
                    alt='iphone'
                    style={{
                        width: '459px',
                        position: 'absolute',
                        right: 120,
                    }}
                />
            </div>
        </>
    );
    const getMobile = () => (
        <div style={{
            borderRadius: '16px',
            background: '#FF7337',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '32px',
            paddingTop: '40px',
            paddingLeft: '21px',
            paddingRight: '21px'
        }}>
            <Tag value="Introduction" />
            <p style={{
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Instrument Sans',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                letterSpacing: '-0.96px',
            }}>
                Welcome to offside
            </p>
            <p
                style={{
                    color: '#F9F9F9',
                    fontFamily: 'Instrument Sans',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textAlign: 'center',
                }}>
                At Offside, we understand that soccer is more than just a sport; it's a passion that brings people together.
            </p>
            <p
                style={{
                    color: '#F9F9F9',
                    fontFamily: 'Instrument Sans',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textAlign: 'center',
                }}>
                Our app is designed to seamlessly integrate every aspect of your soccer journey, from booking the perfect pitch to rallying your team for an epic match.
            </p>
            <img
                src={iPhoneEventsPageImage}
                alt='iphone'
                style={{
                    width: '259px',
                }}
            />
        </div>
    );
    return isMobile ? getMobile() : getDesktop();
}

export default IntroductionCard;
