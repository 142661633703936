import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from "react";
import CheckoutForm from "./checkoutForm";

const StripePage = (): JSX.Element => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);
  const [clientSecret, setStripeClientSecret] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  // const options = {
  //   mode: 'payment' as const,
  //   amount: 1099,
  //   currency: 'cad',
  //   appearance: {
  //     /*...*/
  //   },
  // };
  const fetchIntent = (): void => {
    const query = new URLSearchParams(window.location.search);
    setCurrency(query.get('currency') ?? "");
    setAmount(query.get('amount') ?? "");
    var data = new URLSearchParams();
    data.append('amount', query.get('amount') ?? "");
    data.append('currency', query.get('currency') ?? "");
    const options = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY as string}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data,
    };
    fetch('https://api.stripe.com/v1/payment_intents', options)
      .then(response => response.json())
      .then(data => {
        setStripeClientSecret(data.client_secret);
      })
      .catch(error => console.error(error));
  };

  useEffect(() => fetchIntent(), []);

  return (
      <>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
        <CheckoutForm currency={currency} amount={amount} />
        </Elements >
      )}
      </>
    );
}

export default StripePage;
