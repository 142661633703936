import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import FeaturesCard from '../../../components/featuresCard/featuresCard';
import Tag from '../../../components/tag/tag';
import { useMediaQuery } from 'react-responsive';
import { IconButton, Box, Container } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import iPhoneEventsPageImage from '../../../assets/images/iphone-events-page.png';
import iPhoneDynamicTeamCoordinationImage from '../../../assets/images/iphone-dynamic-team-coordination.png';
import iPhonePlayerRatingImage from '../../../assets/images/iphone-player-rating.png';
import iPhoneCustomRulesImage from '../../../assets/images/iphone-custom-rules.png';

const FeaturesSection = (): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [listRef, setListRef] = useState<HTMLDivElement | null>();
  const getDesktop = (): JSX.Element => (
    <Container>
      <Row style={{ marginBottom: '40px' }}>
        <Col style={{ marginRight: '20px' }}>
          <FeaturesCard
            header="Effortless Pitch Booking"
            bodyText1="Say goodbye to the hassle of reserving soccer pitches."
            bodyText2="You can now book your preferred field in a quick, simple way tailored to your schedule"
            image={iPhoneEventsPageImage}
          />
        </Col>
        <Col>
          <FeaturesCard
            header="Dynamic Team Coordination"
            bodyText1="Building your dream team has never been easier."
            bodyText2="Invite friends, coordinate matches, and experience the joy of playing alongside your favorite teammates."
            image={iPhoneDynamicTeamCoordinationImage}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginRight: '20px' }}>
          <FeaturesCard
            header="Player Ratings"
            bodyText1="See your teammates’ ratings in terms of physique, sprinting, passing, etc."
            bodyText2="Uncover the winning formula and elevate your game with offside’s insightful player ratings."
            image={iPhonePlayerRatingImage}
          /></Col>
        <Col>
          <FeaturesCard
            header="Customizable Rules"
            bodyText1="Tailor your soccer experience to your liking."
            bodyText2="Offside lets you set your own rules, creating a personalized and enjoyable playing environment."
            image={iPhoneCustomRulesImage}
          />
        </Col>
      </Row>
    </Container>
  );
  const getMobile = (): JSX.Element => (
    <Box>
      {/* <List ref={(ref) => setListRef(ref)}></List>
      <Container ref={(ref) => setListRef(ref)}></Container> */}
      <Container sx={{ 
        display: 'flex',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          width: 0,  /* Remove scrollbar space */
          background: 'transparent',  /* Optional: just make scrollbar invisible */
      }
      }} ref={(ref) => setListRef(ref)}>
        <FeaturesCard
          header="Effortless Pitch Booking"
          bodyText1="Say goodbye to the hassle of reserving soccer pitches."
          bodyText2="You can now book your preferred field in a quick, simple way tailored to your schedule"
          image={iPhoneEventsPageImage}
        />
        <div style={{ width: '24px', }} />
        <FeaturesCard
          header="Dynamic Team Coordination"
          bodyText1="Building your dream team has never been easier."
          bodyText2="Invite friends, coordinate matches, and experience the joy of playing alongside your favorite teammates."
          image={iPhoneDynamicTeamCoordinationImage}
        />
        <FeaturesCard
          header="Player Ratings"
          bodyText1="See your teammates’ ratings in terms of physique, sprinting, passing, etc."
          bodyText2="Uncover the winning formula and elevate your game with offside’s insightful player ratings."
          image={iPhonePlayerRatingImage}
        />
        <FeaturesCard
          header="Customizable Rules"
          bodyText1="Tailor your soccer experience to your liking."
          bodyText2="Offside lets you set your own rules, creating a personalized and enjoyable playing environment."
          image={iPhoneCustomRulesImage}
        />
      </Container>
      <Container style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
        <IconButton style={{ backgroundColor: 'black' }} onClick={() => {
          listRef?.scrollTo(listRef?.scrollLeft - 20, 0);
        }}>
          <ArrowBackRoundedIcon style={{ color: 'white' }} />
        </IconButton>
        <Box sx={{ width: '32px' }} />
        <IconButton style={{ backgroundColor: 'black' }} onClick={() => {
          listRef?.scrollTo(listRef?.scrollLeft + 20, 0);
        }}>
          <ArrowForwardRoundedIcon style={{ color: 'white' }} />
        </IconButton>
      </Container>
    </Box>
  );
  return (
    <section style={{ padding: isMobile ? '5% 10px' : '5% 50px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: isMobile ? '48px' : '100px' }}>
        <Tag value="Features" />
        <div style={{ width: '80%', marginTop: '20px' }}>
          <span
            style={{
              color: '#FF7337',
              textAlign: 'center',
              fontFamily: 'Instrument Sans',
              fontSize: isMobile ? '32px' : '80px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              letterSpacing: '-1.6px',
            }}
          >Beyond the Pitch: </span>
          <span
            style={{
              color: '#000',
              textAlign: 'center',
              fontFamily: 'Instrument Sans',
              fontSize: isMobile ? '32px' : '80px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              letterSpacing: '-1.6px',
            }}
          >
            A Closer Look at Offside’s Standout Features
          </span>
        </div>
      </div>
      {isMobile ? getMobile() : getDesktop()}
    </section>
  );
}

export default FeaturesSection;
