import { signInWithEmailAndPassword } from "firebase/auth";
import { ref, deleteObject } from "firebase/storage";
import { deleteDoc, doc, collection, query, where, getDocs } from "firebase/firestore";
import {
    FormControl,
    TextField,
    Container,
    Button,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';
import { useState } from "react";
import { auth, firestore, storage } from "../..";

const DeleteAccountPage = (): JSX.Element => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<{
        open: boolean,
        type: "success" | "error",
        messasge: string,
    }>({
        open: false,
        type: "success",
        messasge: "",
    });
    const closeToast = () => {
        setShowToast((prev) => ({ ...prev, open: false }));
    }
    const clearInputs = () => {
        setEmail("");
        setPassword("");
    }
    const getUserByEmail = async (email: string) => {
        const q = query(collection(firestore, "users"), where("email", "==", email));
        return await getDocs(q);
    }
    const deleteUserProfilePicture = (imageUrl: string) => {
        if (imageUrl) {
            // Create a reference to the file to delete
            const profilePictureRef = ref(storage, imageUrl);
            // Delete the file
            deleteObject(profilePictureRef).then(() => {
                // File deleted successfully
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });
        }
    }
    const deletUserFromFirestore = async (userId: string) => {
        try {
            const docRef = doc(firestore, 'users', userId);
            await deleteDoc(docRef);
        } catch (error) {
            setShowToast({
                open: true,
                type: "error",
                messasge: "An error has occurred deleting this account"
            });
        }
    }
    const deleteUserAccount = async () => {
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const userAuth = userCredential.user;
                let snapshots = await getUserByEmail(userAuth.email ?? "");
                deleteUserProfilePicture(snapshots.docs[0].get("imageUrl"));
                await deletUserFromFirestore(snapshots.docs[0].id);
                userAuth.delete();
                clearInputs();
                setLoading(false);
                setShowToast({
                    open: true,
                    type: "success",
                    messasge: "Your account has been successfully deleted"
                });
            })
            .catch((error) => {
                let messasge = "An error has occurred deleting this account";
                switch (error.code) {
                    case 'auth/invalid-credential':
                        messasge = "Email/Password incorrect";
                        break;
                }
                setLoading(false);
                setShowToast({
                    open: true,
                    type: "error",
                    messasge,
                });
            });
    }

    return (
        <form
            style={{ height: "100%" }}
            onSubmit={(event) => {
                event.preventDefault();
                deleteUserAccount();
            }}
        >
            <Snackbar open={showToast.open} autoHideDuration={6000} onClose={() => closeToast()}>
                <Alert onClose={() => closeToast()} severity={showToast.type} sx={{ width: '100%' }}>
                    {showToast.messasge}
                </Alert>
            </Snackbar>
            <Container
                style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Container style={{ marginBottom: '24px', width: "300px", padding: 0 }}>
                    <Typography variant="h5" marginBottom={1}>
                        Delete Account
                    </Typography>
                    <Typography variant="h6" fontSize={15}>
                        Deleting your account will remove all of your information from our database (Offside). This cannot be undone.
                    </Typography>
                </Container>
                <FormControl style={{ marginBottom: '32px', width: "300px" }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        value={email}
                        label="Email"
                        variant="outlined"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </FormControl>
                <FormControl style={{ marginBottom: '32px', width: "300px" }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        value={password}
                        label="Password"
                        variant="outlined"
                        type="password"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </FormControl>
                <Button
                    disabled={loading}
                    type="submit"
                    style={{ width: "300px", height: "40px" }}
                    variant="contained"
                    color="error"
                    disableElevation
                >
                    {
                        loading ? (
                            <CircularProgress color="secondary" size={20} sx={{ color: "white" }} />
                        ) : (
                            <Typography variant="h6" fontSize={15}>
                                Delete Account
                            </Typography>
                        )
                    }
                </Button>
            </Container>
        </form>
    );
}

export default DeleteAccountPage;
