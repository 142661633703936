import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'

interface SocialButtonProp {
  icon: string,
  header: string,
  subHeader: string,
  onClick: MouseEventHandler<HTMLButtonElement>,
}

const SocialButton = ({ icon, header, subHeader, onClick, ...props }: SocialButtonProp): JSX.Element => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <Button onClick={onClick} variant="dark" style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '8px' }}>
      <div style={{ marginRight: '12px' }}>
        <img src={icon} alt='apple' height={isTabletOrMobile ? '34px' : '48px'} />
      </div>
      <div style={{
        display: "flex",
        flexDirection: 'column',
        alignItems: 'start'
      }}>
        <p style={{ color: '#F1F1F1', fontWeight: '500', fontSize: isTabletOrMobile ? '10px' : '12px' }}>{header}</p>
        <p style={{ color: '#F1F1F1', fontWeight: '500', fontSize: isTabletOrMobile ? '15px' : '24px' }}>{subHeader}</p>
      </div>
    </Button>
  );
}

SocialButton.defaultProps = {
  body: undefined,
};

export default SocialButton;
