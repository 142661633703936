import {
    PaymentElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';

interface CheckoutFormProps{
    currency: string,
    amount: string,
}

const CheckoutForm = ({ currency, amount}: CheckoutFormProps): JSX.Element => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            return;
        }
        setIsProcessing(true);
        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required',
        });
        setIsProcessing(false);
        if (error) {
            console.log("this is error");
            if ((window as any).onErrorHandler) {
                (window as any).onErrorHandler.postMessage(true);
            }
        } else {
            console.log("this is success");
            if ((window as any).onSuccessHandler) {
                (window as any).onSuccessHandler.postMessage(true);
            }
        }
    };
    const calculateAmount = (): String => {
        let calculatedAmount = (parseInt(amount)) / 100;
        return calculatedAmount.toString();
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Container style={{ paddingTop: "20px", fontFamily: "Instrument Sans !important"}}>
                    <p style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        marginBottom: "30px",
                    }}>
                      Enter Your card details to pay
                    </p>
                    <PaymentElement />
                    <Button
                      type='submit'
                      style={{ marginTop: "24px", width: '100%' }}
                      variant="primary">
                        {isProcessing ? "Processing..." : `Pay ${currency.toUpperCase()} ${calculateAmount()} `}
                    </Button>
                </Container>
            </form>
        </>
    );
}

export default CheckoutForm;
