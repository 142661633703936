import { Navbar, Button } from "react-bootstrap";
import logo from './../../assets/images/logo.svg';
import IntroductionCard from "../../components/introductionCard/introductionCard";
import FeaturesSection from "./sections/featuresSection";
import FooterSection from "./sections/footerSection";
import IndexSection from "./sections/indexSection";
import TakeTheFieldAnywhereSection from "./sections/takeTheFieldAnywhereSection";
import { useMediaQuery } from 'react-responsive'

const LandingPage = (): JSX.Element => { 
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <div className="App">
      <header className="App-header">
        <Navbar expand="lg" className="App-navbar" style={{ backgroundColor: 'transparent !important', padding: isTabletOrMobile ? '20px 20px' : '20px 120px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt='logo' style={{ marginRight: '8px' }} height={isTabletOrMobile ? 40 : 64} />
            <span style={{ color: '#000000', fontWeight: '600', fontSize: isTabletOrMobile? '20px' : '40px' }}>Offside.</span>
          </div>
          <Button variant="dark">Download the app</Button>
        </Navbar>
      </header>
      <IndexSection />
      <section style={{ backgroundColor: 'white', padding: '5%' }}>
        <IntroductionCard />
      </section>
      <FeaturesSection />
      <TakeTheFieldAnywhereSection />
      <FooterSection />
    </div>
);
}

export default LandingPage;