import appleIconBlack from '../../../assets/images/apple-icon-black.svg';
import googlePlaystoreIcon from '../../../assets/images/play-store-icon.svg';
import iphoneSlantingImage from '../../../assets/images/iphone-slanting.png';
import SocialButtonAlt from '../../../components/socialButton/socialButtonAlt';
import { useMediaQuery } from 'react-responsive';

const TakeTheFieldAnywhereSection = (): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const getDesktop = () => (
    <section style={{ background: '#252525', display: 'flex', flexDirection: 'column', alignItems: 'start', padding: '5%' }}>
      <p
        style={{
          color: '#FFF',
          textAlign: 'left',
          fontFamily: 'Komika Slim',
          fontSize: '56px',
          fontStyle: 'italic',
          fontWeight: '400',
          lineHeight: 'normal',
          letterSpacing: '-1.12px',
          marginBottom: '32px'
        }}
      ><span>Take the Field, </span>
        <span style={{ color: '#FF7337' }}>anywhere</span>
      </p>
      <div style={{ width: '45%' }}>
        <p style={{
          color: '#FFF',
          fontFamily: 'Instrument Sans',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          letterSpacing: '-0.48px',
          textAlign: 'start',
          marginBottom: '16px'
        }}>
          With Offside in your pocket, soccer is not just a game; It’s an adventure waiting to happen.
        </p>
        <p style={{
          color: '#FFF',
          fontFamily: 'Instrument Sans',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          letterSpacing: '-0.48px',
          textAlign: 'start',
        }}>
          Download the app now and carry the thrill of the pitch wherever you go.
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "72px" }}>
        <SocialButtonAlt icon={appleIconBlack} header='Download on the' subHeader='App Store' onClick={() => { }} />
        <div style={{ width: "32px" }} />
        <SocialButtonAlt icon={googlePlaystoreIcon} header='Get it on' subHeader='Google Play' onClick={() => { }} />
      </div>
    </section>
  );
  const getMobile = () => (
    <section style={{ background: '#252525', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '80px 21px 0px 21px' }}>
      <div
        style={{
          color: '#FFF',
          textAlign: 'center',
          fontFamily: 'Komika Slim',
          fontSize: '26px',
          fontStyle: 'italic',
          fontWeight: '400',
          lineHeight: 'normal',
          letterSpacing: '-1.12px',
          marginBottom: '32px'
        }}
      >
        <p>Take the Field, </p>
        <p style={{ color: '#FF7337' }}>anywhere</p>
      </div>
      <div>
        <p style={{
          color: '#FFF',
          fontFamily: 'Instrument Sans',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          letterSpacing: '-0.48px',
          textAlign: 'center',
          marginBottom: '16px'
        }}>
          With Offside in your pocket, soccer is not just a game; It’s an adventure waiting to happen.
        </p>
        <p style={{
          color: '#FFF',
          fontFamily: 'Instrument Sans',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          letterSpacing: '-0.48px',
          textAlign: 'center',
        }}>
          Download the app now and carry the thrill of the pitch wherever you go.
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "72px", marginBottom: "52px" }}>
        <SocialButtonAlt
          icon={appleIconBlack}
          header='Download on the'
          subHeader='App Store'
          onClick={() => {
            const url = 'https://apps.apple.com/us/app/offside/id6474705013';
            window.open(url, '_blank');
          }}
        />
        <div style={{ width: "32px" }} />
        <SocialButtonAlt icon={googlePlaystoreIcon} header='Get it on' subHeader='Google Play' onClick={() => { }} />
      </div>
      <img src={iphoneSlantingImage} width="332" alt='slanting-phone' />
    </section>
  );
  return isMobile ? getMobile() : getDesktop();
}

export default TakeTheFieldAnywhereSection;
