import { addDoc, collection } from "firebase/firestore";
import {
    FormControl,
    TextField,
    Container,
    Button,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';
import { useState } from "react";
import { firestore } from "../..";
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

const SupportPage = (): JSX.Element => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<{
        open: boolean,
        type: "success" | "error",
        message: string,
    }>({
        open: false,
        type: "success",
        message: "",
    });
    const closeToast = () => {
        setShowToast((prev) => ({ ...prev, open: false }));
    }
    const clearInputs = () => {
        setName("");
        setEmail("");
        setMessage("");
    }
    const createSupport = async () => {
        if (!name || !email || !message) {
            setShowToast({
                open: true,
                type: "error",
                message: "All fields are required",
            });
            return;
        }
        setLoading(true);
        await addDoc(collection(firestore, "support"), {
            name: name,
            email: email,
            message: message
          })
          .then(async () => {
            clearInputs();
            setLoading(false);
            setShowToast({
                open: true,
                type: "success",
                message: "Support ticket created",
            });
        })
          .catch((error) => {
            setLoading(false);
            setShowToast({
                open: true,
                type: "error",
                message: "An error has occurred deleting this account",
            });
        });
    }

    return (
        <form
            style={{ height: "100%" }}
            onSubmit={(event) => {
                event.preventDefault();
                createSupport();
            }}
        >
            <Snackbar open={showToast.open} autoHideDuration={6000} onClose={() => closeToast()}>
                <Alert onClose={() => closeToast()} severity={showToast.type} sx={{ width: '100%' }}>
                    {showToast.message}
                </Alert>
            </Snackbar>
            <Container
                style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Container style={{ marginBottom: '24px', width: "300px", padding: 0 }}>
                    <Typography variant="h5" marginBottom={1}>
                        How can we help ?
                    </Typography>
                </Container>
                <FormControl style={{ marginBottom: '32px', width: "300px" }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        value={name}
                        label="Name"
                        variant="outlined"
                        // error={!name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </FormControl>
                <FormControl style={{ marginBottom: '32px', width: "300px" }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        value={email}
                        label="Email"
                        variant="outlined"
                        type="email"
                        // error={!email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </FormControl>
                <FormControl style={{ marginBottom: '32px', width: "300px" }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        value={message}
                        label="Message"
                        variant="outlined"
                        // error={!message}
                        onChange={(event) => setMessage(event.target.value)}
                    />
                </FormControl>
                <Button
                    disabled={loading}
                    type="submit"
                    style={{ width: "300px", height: "40px" }}
                    variant="contained"
                    color="primary"
                    disableElevation
                >
                    {
                        loading ? (
                            <CircularProgress color="secondary" size={20} sx={{ color: "white" }} />
                        ) : (
                            <Typography variant="h6" fontSize={15}>
                                Send message
                            </Typography>
                        )
                    }
                </Button>
                <Typography variant="h6" fontSize={15} sx={{ marginTop: '20px' }}>
                    Contact Information
                </Typography>
                <Box>
                    <LocalPhoneRoundedIcon sx={{ marginRight: '8px' }} />
                    <span>+1 368-995-5233</span>
                </Box>
                <Box>
                    <EmailRoundedIcon sx={{ marginRight: '8px' }} />
                    <span>admin@offside.soccer</span>
                </Box>
            </Container>
        </form>
    );
}

export default SupportPage;
