import appleIcon from './../../../assets/images/apple-icon.svg';
import googlePlaystoreIcon from './../../../assets/images/play-store-icon.svg';
import planetImage from './../../../assets/images/planet.svg';
import SocialButton from '../../../components/socialButton/socialButton';
import { useMediaQuery } from 'react-responsive'

const IndexSection = (): JSX.Element => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const getSubtitleDesktop = (): JSX.Element => (
        <>
            <p style={{ color: '#000000', fontWeight: '500', fontSize: '15px' }}>
                Discover the ultimate soccer playground at your fingertips. Book your favorite pitch, invite friends, and
            </p>
            <p style={{ color: '#000000', fontWeight: '500', fontSize: '15px' }}>
                and kick off unforgettable matches with offside
            </p>
        </>
    );
    const getSubtitleMobileAndTablet = (): JSX.Element => (
        <div style={{ marginTop: '22px' }}>
            <p style={{ color: '#000000', fontWeight: '400', fontSize: '15px' }}>
                Discover the ultimate soccer playground at your
            </p>
            <p style={{ color: '#000000', fontWeight: '400', fontSize: '15px', marginBottom: '12px' }}>
                fingertips
            </p>
            <p style={{ color: '#000000', fontWeight: '400', fontSize: '15px' }}>
                Book your favorite pitch, invite friends, and kick
            </p>
            <p style={{ color: '#000000', fontWeight: '400', fontSize: '15px' }}>
                off unforgettable matches with offside
            </p>
        </div>
    );
    return (
        <section style={{ position: "relative" }}>
            <img src={planetImage} alt='planet' height={isTabletOrMobile ? '450px' : '750px'} width={isTabletOrMobile ? '350px' : '750px'} />
            <div style={{ position: 'absolute', top: isTabletOrMobile ? '100px' : '180px', left: '0', right: '0', }}>
                <p style={{ color: '#000000', fontWeight: '600', fontSize: isTabletOrMobile ? '30px' : '100px' }}>
                    Your Field. Your Game.
                </p>
                <p style={{ color: '#FF7337', fontWeight: '600', fontSize: isTabletOrMobile ? '30px' : '100px' }}>
                    Your Rules.
                </p>
                {isTabletOrMobile ? getSubtitleMobileAndTablet() : getSubtitleDesktop()}
                <div style={{ display: "flex", justifyContent: "center", marginTop: isTabletOrMobile ? "40px" : "72px" }}>
                    <SocialButton
                        icon={appleIcon}
                        header='Download on the'
                        subHeader='App Store'
                        onClick={() => {
                            const url = 'https://apps.apple.com/us/app/offside/id6474705013';
                            window.open(url, '_blank');
                        }}
                    />
                    <div style={{ width: "32px" }} />
                    <SocialButton
                      icon={googlePlaystoreIcon}
                      header='Get it on'
                      subHeader='Google Play'
                      onClick={() => { }}
                    />
                </div>
            </div>
        </section>
    );
}

export default IndexSection;
